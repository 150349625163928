/* CSS for major rivers caused flood */
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .checkbox-container {
    width: 20%; /* Each item takes up 20% of the container width */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
    padding: 5px; /* Adjust padding as needed */
  }
  
  .checkbox-container input {
    margin-right: 10px;
  }
  
  .checkbox-container label {
    color: white; /* Make the text white */
  }
  