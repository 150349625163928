.add_new_title {
  position: absolute;
  right: 518px;
  /* left: 539px; */
  bottom: 202px;
  font-size: 12px;
  color: var(--active-color);
}
.form-control:disabled{
  background-color: transparent !important;
  opacity: 0.4;
}
#add_new_titlecontact{
  font-size: 12px;
  color: var(--active-color);
}
.add_new_title_user_container {
  position: relative !important;
  display: flex;
  justify-content: flex-end;
}

.add_new_title_user {
  position: absolute;
  left: 413px;
  bottom: 160px;
  cursor: pointer;
  font-size: 12px;
  color: var(--active-color);
}
