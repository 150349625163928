
.add_config {
  font-size: 11px;
  background-color: #18a3a0 !important;
   width:120px
}

.config_data_tbl_container{
    display: flex;
    flex-direction: column;
}