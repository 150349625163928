.tag{
    background-color: var(--primary-color) !important;
    border: none !important;
    padding: 3px 9px 2px 9px!important;
    display: inline-block;
    color: var(--white-font-color);
    font-size: 10px;
}
.tag-remove {
    color: var(--white-font-color);
    /* font-size: 75%; */
    line-height: 100%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    margin-left: 9px;
    font-size: 12px;
    font-weight: bold;
}