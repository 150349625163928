.dashboard_card {
  background-color: var(--secondary-color);
  flex: 1;
  padding: 1rem 1rem;
  border-radius: 0.3rem;
}

.ds-single_card {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 0.7rem;
}
.ds-single_card.highlighted {
  border: 3px solid var(--white-font-color);
}
.card-label {
  font-size: 0.8rem;
}
