.datepicker__label {
  color: #ffff;
 margin-bottom: 0.2rem;
}

::-webkit-calendar-picker-indicator {
  background-image: url(../../../../../Assets/Icons/datepicker.svg);
}
.date__container .form__input {
  background-color: var(--secondary-color);
  color: var(--white-font-color);
}
.date__container .form-control:focus {
  background-color: var(--secondary-color);
  color: var(--white-font-color);
}
