.lms__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0.9rem 1rem;
  position: relative;
  background-color: var(--secondary-color);
  color: var(--white3-color);
  border-radius: 5px;
}

.lms__title {
  font-size: 1rem;
  color: var(--white3-color);
}

.lms__title_icon {
  font-size: 1rem;
}

.lms__icons {
  padding-right: 8px;
}

.lms__subname {
  padding-right: 100px;
}

.lms__status--transit {
  color: var(--red-color);
  font-size: 1rem;
  padding-left: 0.4rem !important;
}

.lms__status--delivery {
  font-size: 1rem;
  padding-left: 0.4rem !important;
}

/* .menu__dropdown {
  background-color: white;
  height: 3rem;
  width: 4rem;
  color: black;
} */
.dropdown__menu--header {
  position: relative;
}

.dropdown__menu--header .dropdown-menu.show {
  position: absolute;
  z-index: 9999999;
}

.custom-toggle {
  padding: 2px 5px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.custom-toggle:hover {
  background-color: #f0f0f0;
}
