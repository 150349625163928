.facility-card {
  display: flex;
  background-color: #b981f6;
  color: var(--white-font-color);
  padding: 0.6rem 1rem;
  gap: 1rem;
  border-radius: 0.2rem;
  margin-top: 0.7rem;
  justify-content: space-between;

}
.facility-card_ora {
  display: flex;
  background-color: #e28771;
  color: var(--white-font-color);
  padding: 0.6rem 1rem;
  gap: 1rem;
  border-radius: 0.2rem;
  margin-top: 0.7rem;
  justify-content: space-between;
}
.facility-card_pin {
  display: flex;
  background-color: #d252aa;
  color: var(--white-font-color);
  padding: 0.6rem 1rem;
  gap: 1rem;
  border-radius: 0.2rem;
  margin-top: 0.7rem;
  justify-content: space-between;

}
.facility-card_value {
  text-align: center;
}

.fc-card-label {
  font-size: 0.6rem;
}
.fc-card-value {
  font-size: 1.2rem;
  font-weight: 600;
}
