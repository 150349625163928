
.wrapper-class label {
    display: inline;
    margin-left: 5px;
  }
  .region_header{
    justify-content: space-between;
  }

  .dms_header{
    background-color: var(--secondary-color);
    padding: 0.8rem;
    border-radius: 0.3rem;
  }

  .region_container{
    background-color: var(--secondary-color);
    border-radius: 0.3rem;
  }