.add_task_sop_mgmnt{
  position: absolute;
  right: 18px;
 font-size: 12px;;
 cursor: pointer;
 color:#ffa100;
}
.add_resource_sop_mgmnt{
  position: absolute;
  left: 39%;
  top: 31.7%;
  font-size: 12px;;
  cursor: pointer;
  color:#ffa100;
}

