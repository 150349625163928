.iccc__table--container {
    border: 1px solid var(--green4text-color);
    padding: 0.5em 1em 0em 1em;
    border-radius: 1em;
    margin: 0 1.3em;
    max-height: 400px;
    overflow-y: auto;
  }
  .cust__table--container {
    border: 1px solid var(--green4text-color);
    padding: 0.5em 1em 0em 1em;
    border-radius: 1em;
    margin: 0 1.3em;
  }
  .iccc--table {
    color: var(--white-font-color);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    width: 100%;
    margin-bottom: 0pt;
  }
 
  .table > :not(caption) > * > * {
    color: var(--white-color);
    background-color: var(--secondary-color);
  }
  #light .table > :not(caption) > * > * {
    color: #040219;
  }
 
  td {
    vertical-align: middle;
  }
  th,
  tr {
    border-color: var(--bluish4-color);
  }
 
  tr:last-child {
    border-color: transparent;
  }
 
  .iccc--table th {
    color: var(--grey3-color);
  }
 
  /*for pagination*/
  .pagination {
    float: right;
    margin-top: 0.5em;
  }
 
  .pagination > button {
    border-radius: 0.3rem;
    background-color: var(--white-font-color);
    width: 1.7rem;
    height: 1.7rem;
  }
 
  .pagination > button.pagination_page {
    background-color: var(--green-color);
    border-color: var(--green-color);
    color: var(--white-font-color);
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    width: 20%;
  }
  .datatable__pageinfo {
    margin: 0px 1rem 4px 1rem;
    background-color: var(--white3-color);
    width: 6rem;
    height: auto;
    text-align: center;
    color: var(--black-color);
  }
 
  .right_pageno {
    margin: 0px 1rem 4px 1rem;
    background-color: var(--white3-color);
    width: 6rem;
    height: auto;
    text-align: center;
    height: 1.5rem;
    color: black;
    border-radius: 0.3rem;
  }
  .pagination_page {
    background-color: var(--green3-color);
  }
 
  .no-data-text {
    font-size: 1em;
    margin-top: 1em;
    color: var(--grey3-color);
  }
 
  .dropdown__table > .select__container {
    color: var(--primary-color);
    border-style: hidden;
    background-color: var(--white-color);
    cursor: pointer;
    padding: 0.1rem 1rem;
    border-radius: 0.3rem;
    text-transform: capitalize;
    height: 2rem;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .iccc--table th:first-child {
    border-top-left-radius: 10px;
  }
  .iccc--table th:last-child {
    border-top-right-radius: 10px;
  }
  .left-align {
    text-align: left;
  }
  .center-align {
    text-align: center;
  }
  .pagecount-style {
    color: var(--white-color);
  }
 
  .tabchanger_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 94%;
    padding: 0.6rem 3rem 0.6rem 3rem;
    margin: 1rem 2rem 1rem 1rem;
    background-color: var(--primary-color);
    color: var(--green-color);
  }
  #light .tabchanger_container {
    background-color: #f3f5f7;
  }
 
  .tab {
    cursor: pointer;
    padding-bottom: 4px; /* Space for the bottom border */
  }
 
  #light .tab {
    color: #909097;
  }
 
  .tab.active {
    border-bottom: 3px solid var(--green-color); /* Green solid bottom border for active tab */
    color: var(--green-color);
  }
  #light .tab.active {
    border-bottom: 3px solid #2b6591;
    color: #2b6591;
  }
  .report__status .highlighted-row td {
    background-color: red !important;
    color: #000;
    font-weight: bold;
  }
 
  .report__status .lightgreen-row td {
    background-color: lightgreen !important;
    color: #000;
    font-weight: bold;
  }
 
  .report__status .yellow-row td {
    background-color: yellow !important;
    color: #000;
    font-weight: bold;
  }
 
  .pagination_gotopage {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .pagination_gotopage-input {
    width: 60px;
    height: 30px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5px;
    padding-left: 2px;
    outline: none;
  }
  .pagination_select {
    margin-right: 0.7rem;
    height: 28px;
    border-radius: 4px;
    outline: none;
  }
  .iccc__table--container::-webkit-scrollbar {
    width: 8px;
  }
  .iccc__table--container::-webkit-scrollbar-track {
    background: var(--primary-color);
    border-radius: 12px;
  }
  .iccc__table--container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }
  /* Make the table header sticky */
  .iccc--table thead th {
    position: sticky;
    top: 0;
  }
 
  .fixed-width-header th {
    white-space: normal;
    word-wrap: break-word; /* Break long words */
    overflow: hidden; /* Ensures content doesn’t overflow */
    text-overflow: ellipsis; /* Add ellipsis if text overflows */
    height: auto; /* Allow dynamic height */
    vertical-align: top; /* Align text to the top */
    border: 1px solid white;
  }
 
  .fixed__header th {
    position: sticky;
    top: 0;
    background-color: #18a3a0;
    z-index: 1;
    color: #fff;
  }
 
  .iccc--table .totals-row td {
    background-color: #108381 !important;
    color: #fffff9 !important;
    font-weight: bold !important;
  }
 



/* 
  .hl-cell {
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
    padding: 3px;
    text-align: left;
  };
  .hl-table {
  width: 100% !important;
  border: 1px solid white !important;
  border-collapse: collapse !important;
  margin-top: 20pt !important;
  };
.hl-div{
  border: 1px solid white;
  overflow-x: auto;
  overflow-y: auto;
  margin-left: 50px;
  margin-right: 50px;
} */

.human-loss-tbl-div{
    border: 1px solid rgb(240, 240, 240);
    border-radius: 12px;
    margin: 5pt 0pt 5pt 0pt;
    overflow-x: auto;
    /* width: 90%; */
}
.human-loss-tbl{
    /* width: 90% !important; */
    color: #fff;
    border-collapse: collapse;
    /* border: 1px solid rgb(240, 240, 240); */
    /* border-radius: 12px; */
}
.human-loss-tbl th{
    color: #fff;
    /* border-top: 1px solid white; */
    /* border-bottom: 1px solid white; */
}
.human-loss-tbl td{
    color: #fff;
    border-top: 1px solid white;
    /* border-bottom: 1px solid white; */
}