.search-form {
  position: relative;
  display: inline-block;
  margin-right: 2em;
}

.search-form input[type="text"] {
  width: 15em;
  height: 2.2em;
  padding: 0.5em;
  border: 1px solid var(--white-font-color);
  border-radius: 0.3em;
  outline: none;
}

.search-form button {
  position: absolute;
  top: -5;
  right: 0;
  height: 2.2em;
  background-color: var(--white-font-color);
  width: 2.2em;
  border: none;
  border-radius: 0.3em;
}
