.map__popup--container {
  font-family: "Poppins", sans-serif !important;
}

.lighting__setting--container {
  position: absolute;
  width: 95%;
  bottom: 2%;
  left: 2%;
  z-index: 10000;
  background-color: var(--secondary-color);
  box-shadow: 0px 1px 27px rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 18px;
}

.lighting__setting--wrapper {
  justify-content: space-between;
  display: flex;
}

.lighting__setting--section {
  display: grid;
  justify-items: center;
  width: 15%;
}
.lighting__setting--link {
  text-decoration: underline;
}

.lighting__setting--rangesection {
  width: 40%;
  justify-items: center;
  display: initial;
}

.lighting__setting--rangecontainer {
  display: grid;
}

.lighting__setting--section1 {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}

.transparent-tooltip {
  background: transparent;
  box-shadow: none;
  color: #fff;
  border: none;
  white-space: pre-wrap;
  text-align: center;
  font-size: 11px !important;
}

.transparent-tooltip::before {
  border: none;
}

.range {
  accent-color: #ffa100;
}

.leaflet-tile-pane {
  /*filter: brightness(0.99) invert(1) contrast(1) hue-rotate(200deg)
    saturate(0.3);*/
}
