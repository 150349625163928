@font-face {
  font-family: "Poppins";
  src: local("Poppins-Medium"),
    url("./Assets/Fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  src: local("Poppins-Medium"),
    url("./Assets/Fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins-Regular"),
    url("./Assets/Fonts/Poppins-Regular.ttf") format("truetype");
}

@media (min-width: 1920px) {
  :root {
    --header-height: 116px;
    --sidebar-width: 290px;
    --sidebar-width-collapsed: 100px;
  }
}
.usertable__actionsicon{
  display: flex;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--primary-color) !important;
  color: var(--white-color);
  font-size: 16px !important;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  body {
    font-size: 10px !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  body {
    font-size: 12px !important;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  body {
    font-size: 12px !important;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  body {
    font-size: 13px !important;
  }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  body {
    font-size: 14px !important;
  }
}

ul {
  list-style: none;
}

a {
  text-decoration: none !important;
  color: unset;
}

.card {
  border: none !important;
  border-radius: 8px !important;
}

.card-header {
  border: none !important;
  background-color: var(--secondary-color) !important;
}

.card-footer {
  border: none !important;
  background-color: var(--secondary-color) !important;
  font-size: 10px;
  color: #54617a !important;
}

.card-body {
  background-color: var(--secondary-color) !important;
  /* padding: 1em !important; */
}

.transform180 {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #ffa100 !important;
  color: #000000 !important;
}
.edashboard {
  width: 100%;
  min-height: calc(100vh - var(--header-height));
}

.grid-color {
  background-color: var(--secondary-color);
}

.grid-container {
  flex-flow: row wrap !important;
  background-color: #1c2038;
  color: #fff;
}

.edashboard__section--1 {
  height: 80px;
  background-color: var(--secondary-color);
}

.grid-item {
  background-color: var(--secondary-color);
  border-radius: 8px;
}

.grid-stack > .grid-stack-item > .grid-stack-item-content {
  left: 0.4em !important;
  right: 0.4em !important;
  top: 0.4em !important;
  bottom: 0.4em !important;
}

.charts-grid-wrapper .grid-stack-item-content {
  background-color: var(--bluish3-color); /* Example: Light background color */
  padding: 2px;
  border-radius: 10px;
  /* Additional styles here */
}

.smartpole__header--transition {
  transition: var(--transition1);
}
.rsourceImage {
  width: 30px;
  height: 30px;
}
.loader-container.body,
.loader-container.box {
  z-index: 2000 !important;
}
.logoutLink {
  position: absolute;
  bottom: 3%;
}
.label_txt {
  color: var(--active-color) !important;
}

:root {
  /* colors */
  --primary-color: #1c2038;
  --secondary-color: #252b48;
  /* --darkblue-color: #171b3a; for print dc table dc_slip__tax-table-value */
  --white-color: #f0f0f7;
  --active-color: #ffa100; /*for map,configdashboard*/
  --black-color: #000000; /* is needed to change in index.html*/
  --login-font-color: #746ba2;
  --disabled-color: #54617a;
  --green-text: #03de73;
  --green-color: #17a3a0;

  /* */
  --grey-color: #979797;
  --grey2-color: #868686; /*waveform__container*/
  --grey3-color: #b6b6b6; /*for map-popup,speedvioldetail key font  see .call__percentage card.css  status.css*/
  --grey4-color: #9b9b9b; /*.audio__button .btn-pause used in ModalAudio.css*/
  --grey5-color: #d9d9d9; /*RadiobuttonGroup,surveillanceCamera,vehicledata*/
  --grey6-color: #b1b1b1; /*timeline*/
  --grey7-color: #6b6b6b; /*Alert*/
  --grey8-color: #b7b7b7;
  --grey9-color: #8c8c8c;
  --grey-rgba-color: #d9d9d980; /*timeline,historytable*/
  --green2-color: #40b2b7;
  --green3-color: #18a3a0;
  --green4-color: #318e87; /*used in charts mostly*/
  --green5-color: #094644; /*used in charts mostly*/
  --green6-color: #1d7170; /*used in charts mostly*/
  --green7-color: #0e5755; /*used in charts mostly*/
  --green8-color: #1eaf2c; /*Alert*/
  --green9-color: #c2f3d6; /*Alert*/
  --white3-color: #ffffff;
  --white-font-color: #ffffff;
  --black2-color: #131313;
  --white2-color: #cfcfcf; /*used for popup confirm deletetable for device intensity #ccc*/
  --red-color: #e95060;
  --red2-color: #f52d56;
  --red-original-color: #ff0000; /*counter-badge*/
  --red3-color: #863d54; /*used for inactive*/
  --red4-color: #ee3525; /*used in Notification.css(Uicomp.)*/
  --red5-color: #ff4d2c;
  --red6-color: #e32326; /*op form button*/

  --brinkPink-color: #ff6584; /* .location_error,signin*/
  --orange-error-color: #f4694c;
  --orange2-color: #feb94d;
  --orange3-color: #f9a50c; /*Alert*/
  --brown-color: #6d6e71;
  --orange4-color: #fff6e7; /*Alert*/
  --orange5-color: #ffa100;
  --orange6-color: #9b7235;
  --placeholder-color: #746e6e;
  --green2-text-color: #007934;
  --green3text-color: #13845d; /*used for active button*/
  --green4text-color: #d9e7db;
  --bluish-color: #22556e; /*.audio__button .btn-play*/
  --bluish2-color: #06152b; /*.date-text Header.css*/
  --bluish3-color: #212529; /*used in lmsHeader*/
  --brownish-color: #444444;
  --bluish4-color: #d9e1e733; /*tr*/
  --bluish5-color: #5a6ace; /*speedviolconfig*/
  --bluish6-color: #eff3f9;
  --bluish7-color: #1573ffcc;
  --pink1-color: #f16a87;
  --pink2-color: #e0726c;
  --pink3-color: #ffd4d9;
  --pink4-color: #f6a2ab;
  /* Dimensions */
  --header-height: 70px;
  --sidebar-width: 220px;
  --sidebar-width-collapsed: 80px;
  --transition1: all 0.3s ease;
  --color-opacity: 0.5;

  /* For Popups */
  --whipopupbgte-: #ffffff;
  --logout-modal-headerbg: #252b48;
  --logoutcontent-color: #fff;
  --modal-header-bg: #ffffff;
  --radio-colorbg: #0075ff; /*change this color*/
  --modalheadertitle-greybg: #818a91;
  --modalheadertitle-activebg: #ffa100;
  --modalheadertitle-greenbg: #17a3a0;
  --modalbackgroundheader-greybg: #252b48;
  --content-color-bg: #444444;
  --popupprimary-color: #252b48;
  --popupdcchallan-color: #252b48;

  /* For Scroll */
  --scroll-thumb: #252b48;
  --scroll-track: #252b48;

  /* for truckicon to not change in other themes but change conditionally used in notifiation component*/
  --green-truck: #17a3a0;
}

#light .table > :not(caption) > * > * {
  border-color: #909097;
}

#light .create-user {
  color: #000;
}
/* This is for popups */
#light .modal-body {
  --white-popupbg: #ffffff;
}
#light .modal-header {
  background-color: var(--white-popupbg) !important;
}
#light .modal-content {
  background-color: var(--white-popupbg) !important;
  --white-popupbg: #fff !important;
  color: var(--darkblue-color) !important;
}
#dark .modal-content {
  background-color: var(--secondary-color) !important;
  color: var(--white-font-color) !important;
}

#dark .modal-header {
  background-color: var(--secondary-color) !important;
  color: var(--white-font-color) !important;
}

#dark .table_confirm_body .modal-body {
  color: var(--white-font-color) !important;
}
#dark .ckmodal .modal-content {
  background-color: var(--secondary-color) !important;
}
#dark .pagecount-style {
  color: var(--darkblue-color) !important;
}

#light .create__resource--label {
  color: var(--darkblue-color) !important;
}
#light .btn-success {
  background-color: var(--darkblue-color) !important;
}
#light .btn-primary {
  background-color: var(--darkblue-color) !important;
}
#light .file__upload {
  border: 2px dashed var(--darkblue-color);
}
#light .file-text {
  color: var(--darkblue-color) !important;
}
#light .sop_label {
  color: #ffa100;
}
#light .sop_info_text {
  color: #000;
}

#light .table__modal__popup .modal-content {
  background-color: #fff !important;
}

#light .accordion-body-style,
.accordion-item .headerstyle {
  background-color: var(--secondary-color) !important ;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
#light .accordion-button {
  background-color: var(--secondary-color) !important ;
  box-shadow: none !important;
}
#light .tag {
  color: #000;
}

#light {
  /*:root iis given so that the color in charts also can be changed*/
  /* --primary-color: #afa3ed; */
  --primary-color: #d9d9d9;
  --secondary-color: #fff;
  --black-color: #fff;
  --green3-color: #2b6591;
  --white3-color: #2b6591;
  --white-font-color: #2b6591 !important;
  --white-color: #2b6591; /*this --white-color is given for common select comp. */
  --bluish6-color: #000;
  --bluish5-color: rgb(209, 161, 169);
  --darkblue-color: #2b6591;
  /* --green-color: #2b6591; */
}
#light .search-form button {
  background-color: #d9d9d9;
  border: 0.8px solid black;
}
#light .category__select {
  border: 1px solid #252b48 !important;
  color: var(--darkblue-color) !important;
  background-color: #fff !important;
}
#light .node_elems {
  color: #2b6591;
}
#light .selected_node {
  background-color: #2b6591;
  color: var(--secondary-color);
}
#light .iccc--table th {
  background-color: var(--darkblue-color) !important;
}

#light .pagination > button.pagination_page {
  background-color: var(--darkblue-color) !important;
  color: var(--secondary-color);
}

#light .table_header__addnewbutton .btn {
  background-color: var(--darkblue-color) !important;
  color: var(--secondary-color);
}
#light .add_config {
  background-color: var(--darkblue-color) !important;
}
#light .right_pageno {
  --white3-color: #fff;
  border: #cfcfcf solid 0.13rem;
  color: #000;
}
#light .pagebtn {
  background-color: var(--primary-color);
  color: #000;
}
#light .content {
  background-color: #d9d9d9 !important;
}

#light .table__confirm .btn {
  background-color: var(--darkblue-color) !important;
}
#light .table_confirm_body {
  color: var(--darkblue-color) !important ;
}
#light .table__cancel .btn {
  color: var(--darkblue-color) !important ;
}

/* #light .modal-title {
  color: red !important ;
}

#dark .modal-title {
  color: red !important ;
} */

#light .css-8y890w-control {
  background: var(--secondary-color) !important;
  --secondary-color: #fff;
  border: 1px solid var(--darkblue-color) !important;
}
#light .css-8y890w-control:active {
  background: var(--secondary-color) !important;
  border: 1px solid var(--darkblue-color) !important;
}
#light .css-8y890w-control:focus {
  background: var(--secondary-color) !important;
  border: 1px solid var(--darkblue-color) !important;
}
#light .affectedtable-header-style {
  background-color: var(--darkblue-color) !important;
  color: var(--white-font-color);
}
#light .css-1pb264h-MultiValueGeneric {
  color: var(--darkblue-color) !important;
}
#light .table-title {
  color: var(--darkblue-color) !important;
}
#light .lngselect {
  background-color: #fff;
  color: var(--darkblue-color) !important;
}

.tooltip-inner {
  background-color: var(--active-color) !important;
  color: var(--black-color) !important;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--active-color) !important;
}

.make-it-center {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.form-heading {
  padding-left: 1.2rem;
  padding-bottom: 1rem;
  color: #ffa100;
}
.active__title {
  color: var(--green-color);
  font-size: 1rem;
}

.widget-container {
  background-color: var(--secondary-color);
  border-radius: 8px;
  margin: 0.5rem;
}

.widget-fullscreen {
  min-height: calc(100vh - (var(--header-height) * 2));
}
.previousbutton-style:hover {
  background-color: var(--green2-color) !important;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0.4rem;
}

.custom-table th,
.custom-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.custom-table th {
  background-color: #18a3a0;
}

.vertical-line {
  border-left: 1px solid #dddddd;
}

.datatable__container{
  display: block;
  position: relative;

}