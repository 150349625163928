.facility-container {
  position: relative;
}
.facility_maplink--position {
  
  cursor: pointer;
  color: var(--active-color);
}

.gis-mapcontainer {
  width:100%;
  height: 70vh;
  float: right;
  padding: 0%;
  margin: 0%;
} 

.modal-position {
    width: 100%;
}
.pac-container{
  z-index: 10000;
}
.assetFormMap{
  height: 400px;
}
