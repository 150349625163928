.tab-switch-container {
    display: flex;
    background-color: var(--primary-color);
    border-radius: 25px;
    padding: 5px;
    width: fit-content;
  }
  
  .tab-item {
    padding: 8px 20px;
    cursor: pointer;
    color: white;
    border-radius: 20px;
    margin: 0 2px;
    transition: all 0.3s ease;
    font-size: 0.7rem;
  }
  
  .tab-item.active {
    background-color: #23b1a4; /* Highlighted tab background */
    color: white;
  }
  

  