.top__nav {
  position: fixed;
  top: 0;
  left: var(--sidebar-width);
  background: var(--primary-color);
  width: calc(100% - var(--sidebar-width));
  height: var(--header-height);
  transition: all 0.3s ease;
  z-index: 2;
}

.top__nav.collapsed {
  left: var(--sidebar-width-collapsed);
  width: calc(100% - var(--sidebar-width-collapsed));
  transition: all 0.3s ease;
}

.top__nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 1.5em;
}

.mine__name {
  color: var(--white-font-color);
}

.top__nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-menu {
  display: flex;
  align-items: center;
  justify-content: center;

  /* cursor: pointer; */
}

.top-menu span,
i {
  color: var(--white3-color) !important;
  font-size: 1em;
}

/* .top-menu span {
  margin-left: 0.6em;
} */

.profile {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: var(--white3-color);
}

.profile img {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  object-fit: cover;
}

.user-details {
  display: flex;
  color: var(--white-font-color);
  height: 3em;
  display: flex;
  flex-direction: column;
  margin: 0.7em;
  /* cursor: pointer; */
  text-align: end;
}

.user-details span {
  line-height: 1.7em;
  margin: 0px 0.5em 0 2em;
}

.user-name {
  font-size: 1em !important;
}

.user-role {
  font-size: 0.8em !important;
}

.notification {
  color: var(--white-font-color);
  position: relative;
}

.notification__badge {
  position: absolute;
  top: -8px;
  right: -8px;
}

.center-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3em;
}

.center-box > select,
.nav-select {
  font-size: 1.2em;
  color: var(--white-font-color) !important;
  border-style: hidden;
  background-color: var(--primary-color) !important;
  cursor: pointer;
  text-decoration: none !important;
}

.nav .center-box > select:focus {
  outline: none;
}

.date-box {
  background-color: var(--white3-color);
  margin: 0.7em;
  padding: 0.7em 1em;
  border-radius: 0.8em;
  height: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.date-text {
  color: var(--bluish2-color);
  font-size: 0.8em;
}

.header-option:active {
  background-color: transparent !important;
  color: var(--black-color);
}

.lngcontainer {
  margin: 1rem 0rem 1rem 48rem;
}

.lngbtn {
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0.3rem;
}

.lngselect {
  background-color: #252b48;
  border: none;
  outline: none;
  color: white;
}
