.searchRsrcContainer{
    top: 30px;
    width: 300px;
    position: relative;
}
.config_data_tbl_container .tabl_container{
    margin-top: -15px!important;
}
.resource__table--icons {
    margin-right: 0.5rem;
}