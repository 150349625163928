.barCompleted {
  background-color: red;
}
/* .bar-label{
    display: none;
} */

.bar-containner {
  display: grid;
  flex-direction: column;
  gap: 3rem;
}
.bar-label{
    font-size: 0.7rem;
}