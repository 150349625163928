body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #fff;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
  box-shadow: inset 0 0 1px #fff;

  /* border: 0.2px solid gray; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

.content {
  min-height: calc(100vh - var(--header-height));
  margin-top: var(--header-height);
  padding: 6px 12px 20px 12px;
  color: var(--white-color);
}

.main__layout {
  margin-left: var(--sidebar-width);
  transition: all 0.3s ease;
}

.main__layout-collapsed {
  margin-left: var(--sidebar-width-collapsed);
  transition: all 0.3s ease;
}
