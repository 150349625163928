#dmsdashboard .btn-group {
  border-radius: 0px;
}
#dmsdashboard button {
  border-radius: 0px;
  font-size: 12px;
}
#dmsdashboard .optionContainer {
  color: #000;
}

#dmsdashboard .active {
  background: #fff !important;
}
#dmsdashboard .btn-info {
  background: #40b2b7;
}

/* popup css */
.custom-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0.4rem;
}
.header-container {
  background-color: var(--secondary-color);
  /* padding: 1rem 0; */
  margin: 0 0.4rem;
}

.toprow--container {
  width: 99.4%;
  margin-left: 0.3rem;
  background-color: var(--secondary-color);
  border-radius: 5px;
  padding-top: 0.8rem;
  padding: 0.6rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.custom-table th,
.custom-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.custom-table th {
  background-color: #18a3a0;
}

.vertical-line {
  border-left: 1px solid #dddddd;
}
.value-style {
  color: var(--active-color);
  cursor: pointer !important;
}
.clear-both {
  clear: both;
}

span.icon_down_dir:before {
  content: "";
  
}
span.icon_down_dir {
  background-image: url("../../../../Assets/Icons/caret_down.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
}
.multiSelectContainer input {
  color: #fff;
}
.single-multiselect .multiSelectContainer input {
  color: #fff;
  width: 3rem;
}
/* .searchBox { 
	border: none;
	font-size: 10px;
	min-height: 10px;
    overflow: scroll;
  } */
.searchWrapper {
  width: 100%;
  border-radius: 1;
  /* background-color: #fff; */
  /* color: #000; */
  padding: 0;
}
.chip {
  padding: 0px 3px;
}
.error-message1{
   /* margin-top: 40px; */
   color: #f4694c;
   /* text-align: center; */
   margin-top: -20px;
   margin-left: 282px;
}
/* 

.date-picker-container_from_date {
  display: flex;
  align-items: center;
  margin-left: px;
} */
/* 
.date-picker-container_to_date{
  display: flex;
  align-items: center;
  margin-left: -20px;
} */