.resource__container {
 background-color: #252b48;
}

.category__select{
border-radius: 0.1rem;
  border: 1px solid #d9e1e7 !important;
  height: 1.7rem;
  width: 100%;
  background-color: #252b48 !important;
  color: #fff !important;
  font-size: smaller;
  box-shadow: none !important;
}

.form_txt_fld{
  border-radius: 0.1rem;
    border: 1px solid #d9e1e7 !important;
    height: 1.7rem;
    width: 100%;
    background-color: #252b48 !important;
    color: #fff !important;
    font-size: smaller;
    box-shadow: none !important;
  }
/* .form__label{
 color: #fff;
 margin: 1rem 0.5rem 1rem 0rem;
} */

.form__header{
   color:#ffa100;
}
