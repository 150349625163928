.profile_button {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.profile-form_label {
  margin-left: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.password_input input {
  padding: 0.3rem;
  border-radius: 0.3rem;
  outline: none;
}
.password_input {
  display: grid;
  gap: 0.5rem;
  padding: 0 1rem;
  margin-top: 0.7rem;
}

.password_input label {
  font-size: 0.9rem;
}

.input-wrapper {
  position: relative;
}

.input-wrapper input {
  padding: 0.3rem;
  border-radius: 0.3rem;
  outline: none;
  width: 100%;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: grey;
}
