.iccc__table-header {
  padding: 1.3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.table_header__addnewbutton .btn {
  background-color: var(--green3-color);
  color: var(--white3-color);
  padding: 0.4rem 0.7rem;
  font-weight: 500;
  font-size: 0.8rem;
  min-width: 6rem;
  margin: 0 0;
}
.table_header__addblockbutton .btn {
  background-color: var(--red-color);
  color: var(--white3-color);
  padding: 0.4rem, 0.7rem;
  font-weight: 500;
  font-size: 0.8rem;
  width: 6rem;
  margin: 0 1rem;
}

.filter__Button {
  margin-right: 33rem;
}
.table-search {
  float: right;
}
