.login-page {
  background-image: url("../../Assets/Images/login_bg.png");
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.login-wrapper {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}

.login-container {
  width: 100%;
  padding: 20px 20px 5px 20px;
  border-radius: 10px;
  background-color: var(--white-color);
  /* text-align: center; */
}

.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__form {
  margin: 20px 20px 0px 20px;
}

.signin__input-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  border-bottom: 1px solid var(--login-font-color);
}

.password-container {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.password-left {
  width: 90%;
}

.password-right {
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--login-font-color);
}

.input-item {
  color: var(--login-font-color);
  border: none;
  height: 40px;
  border-bottom-width: 1px;
  font-size: 14px;
  background-color: var(--white-color);
  /* background-color: #eee; */
}
::placeholder {
  color: var(--login-font-color);
}

label {
  font-size: 12px;
  color: var(--login-font-color);
}

.input-item:focus {
  outline: none;
}

.signin-button {
  background-color: var(--primary-color) !important;
  color: white;
  font-size: 1em;
  padding: 0.4em 30% !important;
  border-radius: 0.4em !important;
  cursor: pointer;
  font-weight: 500;
}

.signin-button:hover {
  background-color: var(--secondary-color) !important;
}

.forgot-pwd {
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  cursor: pointer;
  text-align: right;
  color: var(--black-color);
}

.heading {
  color: var(--primary-color);
  font-size: 1.4em;
}

.sub-heading {
  color: var(--primary-color);
  font-size: 1em;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
}

.facing-issues {
  margin-top: 3em;
  font-size: 0.7em;
  font-weight: 100;
  color: var(--black-color);
}

.contact-us {
  font-size: 0.7em;
  font-weight: 100;
  color: var(--black-color);
  cursor: pointer;
  text-decoration: underline !important;
}

.logo {
  width: 85%;
  height: auto;
  margin-bottom: 10px;
}

.signin__error {
  text-align: left !important;
  font-size: 0.7em;
  color: #ff6584;
}
