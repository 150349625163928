
/*# sourceMappingURL=bootstrap.min.css.map */
.react-form-builder .react-form-builder-preview {
    background: transparent !important;
    width:74% !important;
    float:left;
  }
  .react-form-builder .react-form-builder-toolbar{
    background: transparent !important;
    width:25% !important;
    float: right;
    height: 500px;
    overflow-y: scroll;
    margin-top: 0% !important;
  }
  .react-form-builder-toolbar{
    border:1px solid var(--white-font-color);
    padding: 1%;;
  }
  .SortableItem,.rdw-editor-main{
    background: transparent !important;
  }
  .rdw-editor-main{
    border:1px solid;
    overflow: none;
  }
  .react-form-builder .react-form-builder-preview .edit-form {
    background-color: #212529 !important;
  }
  .float-left{
    float:left
  }
  .float-right{
    float:right
  }
  .SortableItem .form-control{
    border-radius:0;
    background-color: transparent;
  }
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    margin: 0.1% 0% 0% 1%;
}
.react-form-builder-preview .form-group{
    margin-bottom: 1rem;
}
.custom-control-input{
    width: 1rem;
    height: 1.25rem;
}
.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    background-color:var(--white-font-color);
    border: var(--grey5-color) solid 1px;
}
.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}
.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
}
.react-form-builder-form .rfb-item label span{
    background: transparent;
    padding: 0;
    font-weight: normal;
    color: var(--active-color);
}
.react-form-builder-form .rfb-item .row{
    padding: 0;
    border: none;
}
.react-form-builder-form .rfb-item .form-group .label-required{
    color:red
}
.SortableItem .form-control{
  color:var(--white-font-color);
  font-size:12px
}
