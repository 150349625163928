.table__confirm--popup .modal-content {
  background-color: var(--secondary-color) !important;
  min-height: 12rem;
  border-radius: 0.6rem;
  width: 25rem;
}
.modal-header {
  border-bottom: none;
}
.table__confirm--popup .modal-body {
  background-color: var(--white-popupbg);
  border-radius: 0.6rem;
}

.table__confirm--popup .modal-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6em;
  line-height: 1.5rem;
  color: var(--grey-color);
}

.table_confirm_body {
  padding: 0rem 1.2rem 1.2rem 1.2rem;
}

.table_confirm_body_msg {
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--white-font-color);
}

.table_confirm_button {
  float: right !important;
  display: flex;
  margin-right: 0.8rem !important;
}

.table__cancel {
  margin-right: 0.8rem !important;
  background-color: var(--grey5-color); /*#cfcfcf*/
  border: none !important;
  height: 2rem;
  width: 6rem;
  color: var(--grey4-color); /*#808080*/
  font-size: 0.8rem;
}

.table__confirm {
  background-color: var(--green-color);
  border: none;
  height: 2rem;
  width: 6rem;
  color: var(--white-font-color);
  font-size: 0.8rem;
}

.popup__name {
  color: var(--green-color);
  font-weight: 700;
  margin-left: 0.4rem;
}

.table_confirm__success-icon {
  margin-right: 0.5rem;
}

.table_confirm_body__success-msg .table_confirm_body_msg {
  margin-top: 0;
  text-align: center;
}
