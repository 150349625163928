
.rivers-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  justify-content: space-between; /* Ensure proper spacing between rows */
}

.river-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Ensure proper spacing between items in a row */
  width: 100%; /* Ensure row takes full width */
  margin-bottom: 10px; /* Add some spacing between rows */
}

.river-item {
  width: 18%;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 10px;
  color: var(--white-font-color);
  display: flex;
  gap: 9px;
}

.river-item label {
  color: var(--white-font-color); /* Ensure the label text is white */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}



  