.map__popup--container{
display: block;
justify-content: center;
align-items: center;
min-width: 200px;
}
.map__popup--title {
  font-size: 14px;
  color: #FFA100;
  display: flex;
  justify-content: center;
  align-items: center;
}


 .labelDiv{
  width: 50%;
 }
 .popUpLabel{
  color:#B6B6B6;
  text-align: right;
line-height: 0;
padding-right: 2px;
 }
 .popUpKey{
  line-height: 0;
  padding-left: 2px;
 }

.map__popup--subtitle {
  font-size: 11px !important;
  display: flex;
}

.react-leaflet-popup .leaflet-popup-content-wrapper {
  border-radius: 5px;
  background-color: var(--secondary-color);
  color: #fff;
}


