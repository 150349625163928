.salesdata__totalsales--labelstyle {
  color: #969fa8;
  margin-right: 0.5rem;
  padding-top: 0.3rem;
}

.totalsalesvalue__container {
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 800;
  padding: 0.2rem 0.1rem 0rem 0rem;
  text-align: center;
}
