.dyna_tbl{
    border: 1px solid rgba(217, 225, 231, 0.2);
    width: 97.5%;
    margin: 0 auto;
}
.dyna_tbl th label {
  color: #fff;
  font-weight: 400;
  line-height: 35px;
}
.dyna_tbl .form__input{
    margin-bottom: 0;;
}
.dyna_tbl tr:last-child {
  border: 1px solid rgba(217, 225, 231, 0.2);
}