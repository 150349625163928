.view__details--label {
  color: var(--active-color);
  text-transform: capitalize;
}

.view__details--label_upper {
  color: var(--active-color);
  text-transform: uppercase;
}

.view__details--value {
  color: var(--white-font-color);
}
