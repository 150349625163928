.block__modal__popup .modal-content {
  background-color: var(--primary-color);
  color: var(--white3-color);
}
.block__modal__popup .modal-title {
  font-size: 14px;
  color: var(--green3-color);
}

.block__modal__popup .modal-header {
  border-radius: none;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 16px;
  color: var(--green-color);
}
.block__modal__popup .modal-footer {
  border-top: none;
}
.block__modal__popup .container {
  padding-left: 0rem;
  top: 0rem;
  padding-top: 0rem;
}
.blockpopup-cross_mark{
  color: var(--green3-color);
}
.block__modal__popup .pagination {
  margin-top: 0.5em;
  padding-left: 2rem;
}
.blockdata_table {
  width: 100%;
  border-collapse: collapse;
}

.blockdata_table th,
.blockdata_table td {
  border: 1px solid var(--white3-color);
  padding: 8px;
}
