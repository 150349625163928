.current-dashboard_card {
  background-color: var(--secondary-color);
  flex: 1;
  padding: 0 0.7rem 1rem 1rem;
}

.cds-single_card {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  color: #fff;
  border-radius: 0.2rem;

}
.curr-card {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.6rem;
}
.cds-single_card.highlighted {
  border: 3px solid var(--white-font-color);
}
.card-label {
  font-size: 0.8rem;
}
