.table__modal__popup .modal-content {
  background-color:  var(--secondary-color) !important;
  color: var(--white-font-color);
}

.table__modal__popup .modal-header {
  border: none !important;
}

.table__modal__popup .modal-footer {
  border: none !important;
  justify-content: center;
}

.create__resource--popup .form-control {
  border: 1px solid var(--grey2-color) !important;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
  font-size: 1em;
}

/* textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
  border-color: black;
} */

.create__resource--popup {
  padding: 0rem 1rem 0rem 1rem;
}

.create__resource--label {
  color: var(--white-font-color);
}
