.form__input {
  border-radius: 0.1rem;
  border: 1px solid var(--white-font-color);
  background-color: var(--secondary-color) !important;
  color: var(--white-font-color) !important;
  font-size: smaller;
  box-shadow: none !important;
  margin-bottom: 1rem;
}

.form__input:focus{
 background-color:var(--secondary-color); 
  border: 2px solid var(--orange5-color) !important; 

}
.form-control:focus{
  background-color:var(--secondary-color); 
  border: 2px solid var(--orange5-color)  !important;
  color: var(--white-font-color);
}

.error__border--color {
  border-radius: 0.1rem;
  border: 1px solid;
  border-color: var(--orange-error-color);
  background-color: var(--secondary-color);
  color: var(--white-font-color);
  box-shadow: none !important;
}

.input_text-form > label {
  color: var(--white-font-color);
  margin-bottom: 0.2rem;
}

.error__message {
  font-size: 0.7rem;
  color: var(--orange-error-color);
  font-style: poppins;
  margin-top: 0.3rem;
} /*this is for the error messgae */

.asterisk {
  color: var(--orange-error-color);
  margin-left: 0.3rem;
}

.form__input:focus {
  background-color: var(--secondary-color);

  border: 2px solid var(--orange5-color) ;
}

.form-control:focus {
  background-color: var(--secondary-color);
  border: 2px solid var(--orange5-color) ;
}

.flexbox{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
