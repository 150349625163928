.themeicon {
  color: var(--white3-color);
  /* color: #d9d9d9; */
  position: relative;
}

.popover {
  min-width: 300px !important;
  width: auto;
}
.themepopup {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
/* Initial styles for radio buttons */
.theme-radio input[type="radio"] {
  display: none; /* Hide the default radio button */
}

.theme-radio label::before {
  content: "";
  display: flex;
  width: 1.3rem;
  cursor: pointer;
  height: 1.3rem;
  border: 2px solid #333; /* Default border color */
  border-radius: 50%;
  margin-right: 5px;
  transition: background-color 0.3s, border-color 0.3s;
}
.hoverlabel {
  display: none;
  position: relative;
  min-width: 8rem;
}
.theme-radio:hover .hoverlabel {
  position: absolute;
  bottom: -0.2rem;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: inline-block;
  background-color: #fff;
  border: 0.1rem solid #ccc;
  padding: 0.5rem;
  border-radius: 0.4rem;
}

.theme-radio label::before {
  opacity: 1;
  visibility: visible;
}
.darkradio:checked + label::before {
  background-color: #1c2038;
  transform: scale(1.5);
  border: 0.2rem solid #ffa100;
}

.darkradio:not(:checked) + label::before {
  background-color: #1c2038;
  border-color: #1c2038;
}

/* Hover effect for the light mode radio button */
.darkradio:hover + label::before {
  transform: scale(1.4);
}

.lightradio:checked + label::before {
  background-color: #fff;
  transform: scale(1.5);
  border: 0.2rem solid #ffa100;
}

.lightradio:not(:checked) + label::before {
  background-color: #fff;
  border-color: #868686;
}

/* Hover effect for the light mode radio button */
.lightradio:hover + label::before {
  /* This style is applied when the radio button is hovered (whether checked or not) */
  transform: scale(1.4);
}
.theme__font {
  font-size: 0.7rem;
  text-align: center;
}
.contrastradio:checked + label::before {
  /* This style is applied when the radio button is checked */
  background-color: #951b1d;
  transform: scale(1.5);
  border: 0.2rem solid #ffa100;
}

.contrastradio:not(:checked) + label::before {
  background-color: #951b1d;
  border-color: #951b1d;
}

.contrastradio:hover + label::before {
  transform: scale(1.4);
}

#light .dark_mode_label {
  color: black;
  font-weight: 550;
}

#dark .dark_mode_label,
.ModeSelectionIcon {
  color: black;
  font-weight: 550;
}
#contrast .dark_mode_label {
  color: black;
  font-weight: 550;
}

.dark_mode_label {
  color: var(--white-font-color);
  font-size: 1rem;
  font-weight: 550;
}

